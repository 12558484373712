import {InertiaFormProps} from '@inertiajs/inertia-react';
import {AnyObjectSchema} from 'yup';
import {createContext} from 'react';

export const FormikContext = createContext<{
  validationSchema: AnyObjectSchema;
  inertia?: InertiaFormProps;
}>({
  validationSchema: {} as AnyObjectSchema,
  inertia: undefined,
});

FormikContext.displayName = 'CustomFormikContext';

export default FormikContext;