import {usePage} from '@inertiajs/inertia-react';
import React, {createContext, useEffect, useState} from 'react';

export const FormErrorContext = createContext({});
FormErrorContext.displayName = 'FormErrorContext';

export const FormErrorContextProvider = ({children}) => {
  const [errors, setErrors] = useState({});
  const {props: inertiaProps} = usePage();

  useEffect(() => {
    setErrors(inertiaProps.errors);
  }, [inertiaProps.errors]);

  return (
    <FormErrorContext.Provider value={{errors, setErrors}}>
      {children}
    </FormErrorContext.Provider>
  );
};

export default FormErrorContext;